// src/components/browse-blog-posts.js
import React from 'react'
import { graphql } from 'gatsby'
import Layout from './layout'
import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

function BrowseBlogPosts({ children, pageContext }) {
  const { groupedPosts, group, page } = pageContext
  const {t} =useTranslation()
  const {language,defaultLanguage} = useI18next()

  return (
    <Layout>
      {group.map(({ node }) => {
        const { title, date, langKey } = node.frontmatter
        const localizedDate =new Date(date).toLocaleDateString(language)
        return (
          <div key={node.id}>
            <header>
              <h1>{title}</h1>
              <em>{t("BlogPage.PostedOn",{localizedDate})}</em>              
              <br />
            </header>
            <p>{node.excerpt}</p>
            <Link to={node.fields.slug}>{t("BlogPage.View")}</Link>
            <hr />
          </div>
        )
      })}
      <footer>
        Pages:{' '}
        {groupedPosts.map((x, index) => {
          const currentPage = index + 1
          return (
            <Link
              key={index}
              to={`/blog/${currentPage}`}
              className={currentPage === page ? 'active' : null}
            >
              {index + 1}
            </Link>
          )
        })}
      </footer>
    </Layout>
  )
}

export default BrowseBlogPosts

export const pageQuery = graphql`
query blogIndex($language: String!) {
  allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
    edges {
      node {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date
          langKey
        }
      }
    }
  }
  locales:allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`